import React from 'react';
import { Link } from 'react-router-dom';
import './Homecont.css';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Homecont = () => {
  return (
    <div>
      <Helmet>
        <title>Kittle Pvt Ltd - IT Services, R&amp;D, Digital Marketing Company</title>
        <meta name='description' content='Kittle Pvt Ltd provides a full set of IT services, research and digital marketing services, Event Management, Scopus | SCI publication, Web of Science Journal publication Assistant, International Journal Publication.' />
        <meta name='keywords' content='Software Development, Web Development, Digital Marketing, SEO, SEM, R&D, UI/UX design, Graphic Design, Consultancy, Scopus and SCI publication, Web of Science Journal publication Assistant, International Journal Publication.' />
      </Helmet>
      <ScrollIntoView>
        <section id="hero-no-slider" className="d-flex justify-cntent-center align-items-center">
          <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <h2>Think Beyond the Limits…</h2>
                <p>Kittle Pvt Ltd is an IT services, Research and Digital Marketing organization that compete with global businesses market. </p>
              </div>
            </div>
          </div>
        </section>
        <section className="services">
          <div className="container">
            <div class="section-title">
              <h2>Our Services</h2>
              <p>Kittle Pvt Ltd, We provide a full set of IT services, research and digital marketing services to drive your company’s growth. We tailored the services to individual needs like Software Development, Web Development, Digital Marketing, SEO, SEM, R&D, UI/UX design, Graphic Design, and Consultancy.</p>
            </div>
            <div className="row">

              <div className="col-md-6 col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                <Link className="icon-box icon-box-pink" to="/itservices">
                  {/* <div className="icon-box icon-box-pink"> */}

                  <div className="icon"><i className="bx bx-windows"></i></div>
                  <h4 className="title">IT Services</h4>
                  <p className="description">We provide cost effective IT services for home users, small and
                    medium sized businesses, and corporates as well with different segment plans.</p>

                  {/* </div> */}
                </Link>
              </div>

              {/* <div className="col-md-6 col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                <Link className="icon-box icon-box-green" to="/digitalmarketing">
                  <div className="icon"><i className="bx bx-hive"></i></div>
                  <h4 className="title">Digital Marketing</h4>
                  <p className="description">Tremendous accomplishments come with great power, choosing
                    digital services from the powerhouse. We at Kittleworks map out the best
                    outcomes to multiply your revenue..</p>
                </Link>
              </div> */}

              <div className="col-md-6 col-lg-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                <Link className="icon-box icon-box-green" to="#">
                  <div className="icon"><i className="bx bx-hive"></i></div>
                  <h4 className="title">KASTER Charitable Trust</h4>
                  <p className="description">KASTER undergo  educational services for the needy and various social services, to meet the standards of society's multifactedly.</p>
                </Link>
              </div>


              <div className="col-md-6 col-lg-4 d-flex align-items-stretch" data-aos="fade-up">
                <Link className="icon-box icon-box-blue" to="/kittlerd">
                  {/* <div className="icon-box icon-box-blue"> */}

                  <div className="icon"><i className="bx bx-world"></i></div>
                  <h4 className="title">Kittle R &amp; D Lab</h4>
                  <p className="description">
                    Kittle Pvt Ltd R &amp; D Lab undertaking all types of Govt and Non Govt research funded projects. Any individuals / College / Institution can collaborate with our R &amp; D Lab and can finish their projects and goals..</p>

                  {/* </div> */}
                </Link>
              </div>



              {/* 
          <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
          <Link className='icon-box icon-box-cyan' to="/eventmanagement">
            {/* <div className="icon-box icon-box-cyan"> 
            
              <div className="icon"><i className="bx bx-customize"></i></div>
              <h4 className="title">Event Management</h4>
              <p className="description">Event management is one of the most thrilling careers available. 
              The average event planner is a busybody in charge of creating a great experience for 
              attendees.</p>
              
            {/* </div> 
            </Link>
          </div> */}

              {/* <div className="col-md-6 col-lg-3 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div className="icon-box icon-box-blue">
              <div className="icon"><i className="bx bx-world"></i></div>
              <h4 className="title"><Link to="/scopusscijournalpublication">SCOPUS/SCI Journal Publication</Link></h4>
              <p className="description">
              We provides standard and quality for the scholars to write paper worldwide which many 
              paper publications in Scopus.</p>
            </div>
          </div> */}

            </div>

          </div>
        </section>


        <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 video-box">
                <img src="assets/img/why-us.jpg" className="img-fluid" alt="whyusimage" />
                {/* <Link to="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></Link> */}
              </div>

              <div className="col-lg-6 d-flex flex-column justify-content-center p-5">

                <div className="icon-box">
                  <div className="icon"><i className="bx bx-fingerprint"></i></div>
                  <h4 className="title">Who We Are...</h4>
                  <p className="description">Kittle is an IT services, research and digital marketing
                    organization that compete with global businesses market and make sure the level of best.
                    With our innovative solutions, we help companies to leverage the power of IT and unleash
                    growth potential. We are proud to be associated with some of the leading brands as their
                    extended team.</p>
                </div>

                <div className="icon-box">
                  <div className="icon"><i className="bx bx-group"></i></div>
                  <h4 className="title">Why We Are...</h4>
                  <p className="description">We're a team of more than 800 experienced professionals who have come together to help
                    business owners turn their visions into reality. With professionally designed IT services, R &amp; D Team,
                    Digital Marketing support and 24/7 emergency service teams we'll make sure that your business growth.</p>

                </div>

              </div>
            </div>

          </div>
        </section>
      </ScrollIntoView>
    </div>
  )
}

export default Homecont;