import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const uiuxdesign = () => {
  return (
    <div>
      <Helmet>
      <title>User Interface & User Experience Designing – Kittle</title>
        <meta name='description' content='Kittle Pvt Ltd is a UX design agency and web design company in India. As a full-service digital design firm, we offer user experience (UX) and user interface.'/>
        <meta name='keywords' content='User Interface design and development, User Experience Designing, UI Designer, UX designer'/>
      </Helmet>
      <ScrollIntoView>
        <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>UI/UX Design</h2>
         <p>We're among the Top UI UX Design Companies in India.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-9.jpg" className="img-fluid" alt="uiuximage"/>
         </div>
         <div className="col-md-7 pt-4">
           <p>
           We work as an extended UX team for companies and startups, bringing an in-depth understanding 
           of the business, design and technical aspects so as to build digital products with a great 
           user experience that affirms the business goals.
          </p>
           <p>
           WOur UI/UX design and development services are focused on creating interfaces that make every 
           digital interaction a delightful user experience.
           </p>
           <p>
           We have been fortunate to work with some of the leading enterprises and startups across the globe and have been 
           listed as one of the best UI UX design company in India.
           </p>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default uiuxdesign;