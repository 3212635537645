import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Industrysol = () => {
  return (
    <div>
      <Helmet>
      <title>Industrial Solutions and Services – Kittle</title>
        <meta name='description' content='Get solutions that meet your industries specific needs from SAS – the world leader in business analytics software and services.'/>
        <meta name='keywords' content='Industrial Solutions and Services, Embedded applications, Customized applications, Industrial friendly applications, Industry software, Billing software, Health Care software'/>
      </Helmet>
    
      <ScrollIntoView>
        <main id="main">
           <section className="features">
     <div className="container">
       <div className="section-title">
         <h2>Industry Solutions</h2>
         <p>
         Our company brings deep industry knowledge to the design and implementation of relevant 
         software-as-a-service solutions, helping organizations thrive amid disruption in sectors 
         such as banking, health care, insurance, and beyond.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-3.jpeg" className="img-fluid" alt="industryimage"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>
             Digital CARE leverages leading-edge solutions to power the operational effectiveness of 
             health-care organizations. Transform care delivery, quality, and patient engagement with a 
             range of strategic solutions that include system selection, benefits and value realization, 
             operational transformation and workflow redesign, systems integration, clinician engagement,
             and large-scale program management.
             </p>
             <p>
             Banking is a complex industry facing widespread technological disruption, and organizations 
             need to stay ahead of the curve. Kittle Digital Banking Solutions leverage game-changing 
             digital technologies to bring agility and efficiency to banking operations and energize 
             customer engagement. Working across retail and commercial banking areas—including 
             transactional banking, wealth management, lending, capital markets, and cards and 
             payments—our solutions range from selection, planning, operational improvement, 
             implementation, and the operation of core technologies..
             </p>
             <p>
             Ask us about these and other Emerging Industry Solutions to discover the power of 
             industry-specific solutions to transform your business.
             </p>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Industrysol