import React from 'react';
import './Itservices.css';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Itservices = () => {
  return (
    <div>
      <Helmet>
      <title>Kittle Pvt Ltd - IT services</title>
        <meta name='description' content='Kittle Pvt Ltd Services is a global leader in IT services, consulting & business solutions with a large network of innovation & delivery centers.'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>IT Services</h2>
         <p>Information technology (IT) services are the services that allow businesses to access the 
             technical tools and information they use for their operational processes and daily tasks. 
             Often, teams with expertise in IT or computer science manage these services for 
             organizations in many industries. Depending on an organization's business type, 
             IT services team can either comprise internal teams or external IT teams.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-19.png" className="img-fluid" alt="itservicesimage"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>There are many IT services that can benefit businesses and help them operate smoothly and 
                 efficiently. Many of these services allow employees to interact with technology that helps 
                 them perform their on-the-job duties or communicate with each other. Some of the most common 
                 types of IT services used in businesses include:</p>
                 <div className='row'>
                 <div className='col-lg-6'>
                     <ul>
                        <li><i className="bi bi-check"></i> Cloud services.</li>
                        <li><i className="bi bi-check"></i> Voice over internet protocol (VoIP)</li>
                        <li><i className="bi bi-check"></i> Backup solutions</li>
                        <li><i className="bi bi-check"></i> Network security</li>
                        <li><i className="bi bi-check"></i> Monitoring</li>
                        <li><i className="bi bi-check"></i> Email services</li>
                     </ul>
                 </div>
                 <div className='col-lg-6'>
           <ul> 
             <li><i className="bi bi-check"></i> Information reporting</li>
             <li><i className="bi bi-check"></i> Software as a service</li>
             <li><i className="bi bi-check"></i> Software development</li>
             <li><i className="bi bi-check"></i> Troubleshooting and technical support</li>
             <li><i className="bi bi-check"></i> Technology training</li>
             <li><i className="bi bi-check"></i> Hardware installations and maintenance</li>
           </ul>
           </div>
           </div>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Itservices