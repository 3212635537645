import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Smm = () => {
  return (
    <div>
      <Helmet>
      <title>Social Media Marketing – Kittle Pvt Ltd</title>
        <meta name='description' content='Kittle is the top most social media marketing company in india seamlessly works as your partner in creating an active online presence through strategic social media planning.'/>
        <meta name='keywords' content='Social Media Marketing, Facebook ads, Youtube ads, Instagram ads, Twitter ads, Social Media Marketing Company, Social Media professionals, Social Media Marketing experts, Social Media Promoter'/>
      </Helmet>
      <ScrollIntoView>
          <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Social Media Marketing (SMM)</h2>
         <p>We're here to give you the best <strong>SMM panel</strong> services to grow your social media accounts. In no 
             time, you will have an unmatchable organic growth of subscribers, followers, and likes. 
             Getting engagement from real people on social media networks will help you to build your 
             brand awareness and online credibility.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-12.jpg" className="img-fluid" alt="smmimage"/>
         </div>
         <div className="col-md-7 pt-4">    
           <p>
           Social media has changed the way we did business forever & the world’s biggest brands are now 
           leveraging this powerful medium for their business. Right from conceptualizing your social 
           media strategy to developing content, identifying the right social media platforms, building 
           user base, developing engaging content, creating viral campaigns, monitoring conversations 
           and promoting your marketing messages, we can be your single source for anything on social 
           media optimization and marketing.
           </p>
           <p>
           Every year, social networks become more and more significant in terms of boosting sales. 
           For instance, in 2019, 43% of internet users used social media when researching products 
           before making a purchase. Although only 12% of people are ready to click a “buy” button on 
           Facebook or Instagram, social networks are highly significant in the early stages of the 
           buyer's journey.
           </p>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Smm;