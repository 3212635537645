import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Dgmarketing = () => {
  return (
    <div>
       <Helmet>
      <title>Digital Marketing - Brand Building & Promotion – Kittle</title>
        <meta name='description' content='Full Service Digital Marketing Agency in India.  Kittle is a trusted digital marketing and brand building company of top clients around the world.'/>
        <meta name='keywords' content='Digital Marketing , Brand Building & Promotion,  Kittle, Brand Setup, Brand promoter, Best digital marketing company,  digital marketing consultancy, digital marketing service provider, digital marketing agency, Search engine optimization, SEM, PPC, Advertisement, Youtube Ads, Social media ads, FB ads'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Digital Marketing</h2>
         <p>
         We follow a data-driven approach to marketing. Choosing the right channel based on data insights from campaigns. Right channel, right message at the right time.
Our multi-channel, online advertising services increase ROI, response rates, and generate leads.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-18.png" className="img-fluid" alt="dgmktimage"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>
             Our Digital Marketing Solutions helps brands to leverage the full potential of Digital Landscape and grow exponentially.
             </p>
           <p>
           Our primary goal is to plan, develop, and implement a variety of digital marketing agency services and campaigns across social media, email marketing, SEO, and Google AdWords.
We are constantly striving to make our clients omnipresent leading to increasing brand presence and lead generation.
So, let’s collaborate together to enhance your brand!
Reach out to us if you’re looking for a growth-oriented adventure! 
           </p>
           <p><strong>Why We Are The Best Digital Marketing Company:</strong></p>
           <ul>
           <li><i className="bi bi-check"></i>Garnered insight and expertise by working with a range of industries.</li>
           <li><i className="bi bi-check"></i>A dedicated team in all facets of digital marketing agency.</li>
           <li><i className="bi bi-check"></i>Offering the best solutions possible! You name it, and we’ve got it!</li>
               </ul>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Dgmarketing