import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Conferences = () => {
  return (
    <div>
      <Helmet>
      <title>International Conference & Event Meet - Kittle</title>
        <meta name='description' content='Conference is professional academic meetings, bridging gaps between industry scholars, experts, and researchers of different fields for sharing and presenting opinions through foster presentations or research papers.'/>
        <meta name='keywords' content='International Conference, Event Meet, Paper Presentation, Conference Proposal, Conference support, Conference publication, Online Library, Online Portal'/>
      </Helmet>
      <ScrollIntoView>
          <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Conferences</h2>
         <p>Kittle Private Ltd., invites the papers from the 
             NATIONAL CONFERENCE, INTERNATIONAL CONFERENCE, SEMINAR conducted by colleges, universities, 
             etc. The Group of articles will accept with some concession and publish in 
             SSRG International Journals as a special issue. It is the platform for college, 
             university, etc., to share knowledge.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-15.png" className="img-fluid" alt="conferences"/>
         </div>
         <div className="col-md-7 pt-4">
         <p>
         Kittle pvt ltd., encourages academicians to guest 
         edit special issues on common topics of interest. Such special issues, introduced by the Guest 
         Editor, focus on speciality domains.
           </p>
           <p>
           General Information of the Special Issues:
           </p>
           <ul>
             <li><i className="bi bi-check"></i> Kittle private ltd., will consider proposals from academicians significantly involved and recognized in their field.</li>
             <li><i className="bi bi-check"></i> Proposals from honorable members of the Review and associated Boards of SSRG are acceptable.</li>
             <li><i className="bi bi-check"></i> Guest editors are eligible to co-publish the special issue with Kittle private ltd.,</li>
             <li><i className="bi bi-check"></i> Guest editors are eligible to apply for Kittle private ltd., Research activities & programs</li>
           </ul>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Conferences