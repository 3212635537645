import React from 'react'
import './Scopussci.css';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Scopussci = () => {
  return (
    <div>
     <Helmet>
      <title>SCOPUS / SCI / Web Science Journals</title>
        <meta name='description' content='Our team of PhD research experts will recommend an appropriate research paper publishing journals such as  SCI, Scopus, Seventh Sense Research Group, Web of Science, Willey, Elsevier, MDPI, IEEE, Springer, Hindawi, Nature.'/>
        <meta name='keywords' content='Journal Publication Support, paper Write up, Thesis write up, Content Write up, Paper Publication, Paper Preparation, Paper Development, SCI & Scopus Publication, Web of Science publication, Willey, Elsevier, MDPI, IEEE, Springer, Hindawi, Nature, SSRG , Seventh Sense Research Group'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>SCOPUS/SCI Journal Publication</h2>
         <p>Scopus is an organization that leads the journal world so; it is the best option to choose 
             it. Scopus journal paper writing offers you different writing skills which will be helpful 
             to write any kind of paper.Our focus is to innovate new ideas and try to share new and good 
             concepts. We provide the idea for helping our clients, not to sell services.  Today, Scopus 
             is the largest database with thousands of scientific publications and books. </p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-20.jpg" className="img-fluid" alt="scopussciimage"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>Publication in Scopus indexed journals has an index and track of various subjects. 
                 We have to maintain an index for records.  Following publication in Scopus indexed 
                 journals are:</p>

                




                     <ul>
                        <li><i className="bi bi-check"></i> IJETT</li>
                        <li><i className="bi bi-check"></i> Inderscience</li>
                        <li><i className="bi bi-check"></i> Willey publications</li>
                        <li><i className="bi bi-check"></i> Springer</li>
                        <li><i className="bi bi-check"></i> IET digital library</li>
                        <li><i className="bi bi-check"></i> ACM digital library</li>
                     </ul>
                     <p>
                     Scopus journal publication in India provides papers of a journal of bioscience, 
                     journal of pediatrics, journal of medical physics, journal of earth science, 
                     business research and perspectives and many more. As we provide the best way to 
                     write your papers in a very effective manner if you want to know about Scopus 
                     journal publication in India so you can contact us.
                     </p>
                 
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Scopussci