import React,{useState} from 'react';
//import Backtotop from './Backtotop';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {

  const [showButton, setShowButton] = useState(false);
  
  const checkScrollTop = () => {
    if(!showButton && window.pageYOffset > 400) {
      setShowButton(true)
    }else if(showButton && window.pageYOffset <= 400) {
      setShowButton(false)
    }
  };
  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };
  window.addEventListener("scroll",checkScrollTop)

  return (
   <>
      <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">

      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Quick Links</h4>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/kittle">Kittle</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/softwaredevelopment">Software Development</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/itinfraservices">IT infra Services</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/industrialsolutions">Industrial Solutions</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/internetofthings">IoT</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/consulting">Consulting</Link></li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <br/><br/>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <Link to="/digitalmarketing">Digital Marketing</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/webdevelopment">Web Development</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/appdevelopment">App Development</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/uiuxdesign">UI/UX Design</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/graphicdesign">Graphic Design</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/seovssem">SEO/SEM</Link></li>
              </ul>
              
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <br/> <br/>
              <ul>
                <li><i className="bx bx-chevron-right"></i> <Link to="/smm">SMM</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/journalpublication">Journal Publication</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/phdpdfassistant">Phd/Pdf Assistant</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/conferences">Conferences</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/patentrights">Patent Rights</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/bookpublications">Book Publications</Link></li>
                <li><i className="bx bx-chevron-right"></i> <Link to="/careers">Careers</Link></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                Kittle Private Limited<br/>
                10/140 General Bazaar Street <br/>
                Thennur, Trichy 620017<br/>
                India <br/><br/>
                <div className='row'>
                  <div className='col-lg-3 col-md-3'>
                    <strong>Phone:</strong>
                  </div>
                  <div className='col-lg-9 col-md-9 '>
                    +91 9578957870<br/>
                  </div>
                </div>
                
                <div className='row'>
                  <div className='col-lg-3 col-md-3'>
                  <strong>Email:</strong> </div>
                  <div className="col-lg-3 col-md-9">
                support@kittle.ltd<br/>
                </div>
                </div>    
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>Kittle</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <Link to="/">Kittle</Link>
        </div>
      </div>
      </footer>  
{/* <Link to="#" className="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></Link> */}
<button onClick={scrollTop} className="back-to-top d-flex align-items-center justify-content-center">
<i className="bi bi-arrow-up-short"></i>
</button>
</>
  )
 
}

export default Footer;