import React from 'react';
import './Career.css';
import { Link } from 'react-router-dom';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Career = () => {
  return (
    <div>
      <Helmet>
      <title>Careers - Kittle</title>
        <meta name='description' content='Job Opportunities from leading software company, IT Industry, Digital Marketing, Publication House, Content Management, Job Openings, Graphic and video editor, YouTube blogger, V logger.'/>
        <meta name='keywords' content='Job Opportunities in Trichy,Madurai, IT Industry, Digital Marketing, Publication House, Content Management, Job Openings, Graphic and video editor, YouTube blogger, V logger'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
        <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>Careers</h2>

          <ol>
            <li><Link to="/">Home</Link></li>
            <li>Careers</li>
          </ol>
        </div>

      </div>
    </section>

    <section id="blog" className="blog">
      <div className="container" data-aos="fade-up">

        <div className="row">

          <div className="col-lg-12 entries">
          {/* <article className="entry">
              <h2 className="entry-title">
              English Editor (or) English Teacher
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> 20000 - 45000 /month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Trichy,India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Verify the English Strength / Writing Style / Grammar Checking in all our office document
/ Writing content based on given idea.<br/>
                  <strong>Job types&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Regular / Permanent<br/>
                  <strong>Schedule&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> BA / MA / Phd <br/>
                  <strong>Skills require&nbsp;:</strong><ul>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;  BA / MA / Phd English literature or Any engineering degree with strong knowledge in English / Anglo Indian Teachers.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;  English Knowledge Must. </li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;  Computer Knowledge - Handling MS office like formatting and editing the content.</li>
                  </ul>
                  
                </p>
              </div>
            </article> */}
  <article className="entry">
              <h2 className="entry-title">
                Job for Full Stack .NET Developer
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> Rs. 30,000/- to Rs. 70,000/- per month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i>  Madurai & Trichy, India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :</strong> Full Stack .NET Developer<br/>
                  <strong>Job types &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Regular / Permanent<br/>
                  <strong>Schedule &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Bachelor in Computer Science<br/>
                  <strong>Skills require &nbsp;:</strong><ul>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Minimum of 4 years of overall software development experience.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Strong experience and proficiency in .NET languages C#, ASP.NET, VB.NET (at least 4 years).</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Experience with SQL databases (MS SQL Server, Oracle) and SQL querying.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Knowledge of web development technologies (HTML, CSS, JavaScript) is desirable.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Develop, test, and maintain software applications using VB.NET and other .NET technologies.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Collaborate with cross-functional teams to gather and analyze requirements.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Design and implement software solutions based on project specifications.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Write clean, efficient, and maintainable code.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Troubleshoot and debug software issues as they arise.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Ensure the timely delivery of high-quality software.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Strong communication and collaboration skills.</li>
                      <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Excellent communication skills and the ability to network.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Self- Driven & Career Oriented.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Strong Leadership Quality.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Target Oriented (Team & Individual).</li>
                      
                  </ul>

                 
                  
                </p>
              </div>
            </article>

            {/* <article className="entry">
              <h2 className="entry-title">
               Jobs for Marketing Manager
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> 20000 - 40000 /month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Kumbakonam, India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :</strong> Marketing Manager<br/>
                  <strong>Job types &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Regular / Permanent<br/>
                  <strong>Schedule &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Master (M.B.A., Marketing)<br/>
                  <strong>Skills require &nbsp;:</strong><ul>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Excellent communication skills and the ability to network.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Self- Driven & Career Oriented.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Strong Leadership Quality.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Ability to handle Pressure.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Should have Good Convincing Skills.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Strong planning ability and analytical skills.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Hands on technical leadership.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Design and manage key marketing activities.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Manage research activities for new market opportunities, and related business strategy development.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Create and implement effective marketing materials for print and digital media.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Planning & Goal Setting.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Successfully plan and implement marketing plans, resulting in identifying interested and qualified decision-makers, and building awareness for the company / services.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Identify, secure, and manage partnerships with outside organizations and internal teams whose contributions will help create newer services and further enhance existing services.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Promotional activities.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Team Management.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Should be Dynamic and Self driven.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Target Oriented (Team & Individual).</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Identifying Training needs and training team members whenever necessary.</li>                
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Plan and execute events, trade shows and product launches.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Evaluate marketing efforts and suggest improvements for future campaigns.</li>
                  </ul>
                  
                </p>
              </div>
            </article> */}

<article className="entry">
              <h2 className="entry-title">
              Job for Full Stack Android Developer  
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> Rs. 30,000/- to Rs. 70,000/- per month.</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Trichy & Madurai, India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full Stack Android Developer.<br/>
                  <strong>Job types&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Permanent <br/>
                  <strong>Schedule&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Bachelor in Computer Science<br/>
                  <strong>Skills require&nbsp;:</strong><ul>


                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Strong knowledge of Android SDK, different versions of Android, and how to deal with different screen sizes.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;An Android app developer must be well-versed with Java programming language.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Familiarity with RESTful APIs to connect Android applications to back-end services.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Strong knowledge of Android UI design principles, patterns, and best practices.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Having knowledge about offline storage, threading, and performance tuning.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Knowledge of the open-source Android ecosystem and the libraries available for common tasks.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Ability to understand business requirements and translate them into technical requirements.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Understanding of Googles Android design principles and interface guidelines.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Proficient understanding of code versioning tools, such as Git.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Familiarity with continuous integration.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Proficiency with SQL and the relational DBM.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Experience with XHTML/HTML, JavaScript, CSS, XML, LAMP or other web technologies</li>




                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Excellent communication skills and the ability to network.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Plans and executes all web, SEO/SEM, database marketing, email, social media, and display advertising campaigns.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Designs, builds, and maintains our social media presence.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Measures and reports performance of all digital marketing campaigns and assesses against goals (ROI and KPIs).</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Identifies trends and insights and optimizes spend and performance based on the insights.</li>
                
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Collaborates with internal teams to create landing pages and optimize user experience.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Utilizes strong analytical ability to evaluate end-to-end customer experience across multiple channels and customer touch points.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Identifies critical conversion points and drop off points and optimizes user funnels.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Evaluates emerging technologies.</li>
                  </ul>
                  
                </p>
              </div>
            </article>

            {/* <article className="entry">
              <h2 className="entry-title">
               Jobs for Digital Marketing  
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> 25000 - 50000 /month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Trichy, India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Digital Marketing Executive.<br/>
                  <strong>Job types&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Permanent <br/>
                  <strong>Schedule&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Master (M.B.A., Female Candidate Preferred)<br/>
                  <strong>Skills require&nbsp;:</strong><ul>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Excellent communication skills and the ability to network.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Plans and executes all web, SEO/SEM, database marketing, email, social media, and display advertising campaigns.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Designs, builds, and maintains our social media presence.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Measures and reports performance of all digital marketing campaigns and assesses against goals (ROI and KPIs).</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Identifies trends and insights and optimizes spend and performance based on the insights.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Brainstorms new and creative growth strategies through digital marketing.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Plans, executes, and measures experiments and conversion tests.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Collaborates with internal teams to create landing pages and optimize user experience.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Utilizes strong analytical ability to evaluate end-to-end customer experience across multiple channels and customer touch points.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Identifies critical conversion points and drop off points and optimizes user funnels.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Collaborates with agencies and other vendor partners.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Evaluates emerging technologies.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Provides thought leadership and perspective for adoption where appropriate.</li>
                  </ul>
                  
                </p>
              </div>
            </article> */}

 

<article className="entry">
              <h2 className="entry-title">
              Job for Graphic Designer
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> Rs. 20,000/- to Rs. 35,000/- per month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Madurai & Trichy,India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Graphic Designer<br/>
                  <strong>Job types&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Regular / Permanent<br/>
                  <strong>Schedule&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Bachelor in Graphic Design, Visual Art or relevant<br/>
                  <strong>Skills require&nbsp;:</strong><ul>

                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;A bachelor's degree in graphic design, visual arts, or a related field is preferred. Equivalent work experience and a strong portfolio may be considered.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Proven more than 3 years of experience as a graphic designer or in a similar role, showcasing a diverse portfolio of design work.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Proficiency in graphic design software and tools, including Adobe Creative Suite (Photoshop, Illustrator, InDesign). </li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Understanding of branding principles and the ability to create designs that align with the company's brand image and values.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Strong organizational skills and the ability to manage multiple projects simultaneously while adhering to deadlines.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Develop innovative and creative designs for digital and print materials, including logos, brochures, social media graphics, banners, advertisements, info graphics, and website layouts.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Apply principles of layout, typography, and composition to create balanced and aesthetically pleasing designs and manipulate and retouch images to improve their quality, enhance visual appeal, and maintain brand consistency.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Collaborate with photographers, and other creative team members to understand project requirements and objectives. </li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Utilize industry-standard design software, such as Adobe Creative Suite (Photoshop, Illustrator, InDesign), to create and edit design assets.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Incorporate feedback and make revisions to designs based on client or stakeholder input.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Ensure all design materials adhere to brand guidelines and maintain consistency in style, color, and typography.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Manage multiple projects simultaneously, prioritize tasks, and meet project deadlines.</li>





                  </ul>
                  
                </p>
              </div>

            </article>



 <article className="entry">
              <h2 className="entry-title">
              Job for Marketing Executive
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> Rs. 20,000/- to Rs. 35,000/- per month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Madurai & Trichy, India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Sound Director<br/>
                  <strong>Job types &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Regular / Permanent<br/>
                  <strong>Schedule &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> MBA in Marketing (Preferred) or relevant<br/>
                  <strong>Skills require &nbsp;:</strong><ul>
                  
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Degree in Marketing, Business Administration, or related field.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Minimum of 4 years of experience in marketing and strong interpersonal and communication skills.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Proven track record of achieving targets and building strong relationships with clients.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Identify and develop new business opportunities through lead generation and prospecting.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Create engaging and informative content for various marketing channels, including social media, email marketing, and website content.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Develop and maintain marketing collateral such as presentations, proposals, and brochures.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Analyze marketing data to measure the effectiveness of campaigns and strategies.</li>


                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Strong knowledge in editing in sounds with photography and video editing.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Creating promotion ads.</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp; Good understanding of background music effects.</li>
                  </ul>
                  
                </p>
              </div>
            </article>
 

            <article className="entry">
              <h2 className="entry-title">
              Jobs for MEAN Stack Developer

              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> Rs. 30,000/- to Rs. 70,000/- per month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Madurai, India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> MEAN Stack Developer.<br/>
                  <strong>Job types&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Regular / Permanent<br/>
                  <strong>Schedule&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Bachelor in Computer Science or relevant<br/>
                  <strong>Skills require&nbsp;:</strong><ul>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Bachelor’s degree in Computer Science or related field.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Minimum of 3 years of experience as a MEAN Stack Developer.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Strong proficiency in front-end web development languages such as HTML, CSS, JavaScript.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Solid understanding of server-side languages such as PHP, Python, Ruby, or Java.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Experience with databases such as MySQL, MongoDB, or PostgreSQL.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Experience with Agile development methodologies and Git version control.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Ability to create wireframes, prototypes, and design concepts using design tools such as Sketch, Adobe, XD, or Figma.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Develop and maintain web applications from concept to deployment using modern web technologies.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Design and build user-friendly interfaces that meet user needs and ensure a consistent user experience across all platforms.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Collaborate with the marketing team to create engaging and visually appealing content for web and mobile applications.</li>

                  </ul>
                  
                </p>
              </div>
            </article>




            <article className="entry">
              <h2 className="entry-title">
               Jobs for React Native Developer

              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> Rs. 30,000/- to Rs. 70,000/- per month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Madurai & Trichy, India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> React Native Developer.<br/>
                  <strong>Job types&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Regular / Permanent<br/>
                  <strong>Schedule&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Bachelor in Computer Science or relevant<br/>
                  <strong>Skills require&nbsp;:</strong><ul>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;3 years of experience as React Native Developer.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Strong understanding of React Native components, lifecycle methods, and state management.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Familiarity with Redux or other state management libraries and with RESTful APIs and asynchronous data handling.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Familiarity with version control systems, such as Git and with native iOS or Android development.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Familiarity with testing frameworks, such as Jest or Enzyme.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Proficiency in front-end technologies such as HTML5, CSS3, and JavaScript, and in modern front-end frameworks (e.g., React, Angular, or Vue.js).</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Familiarity with back-end technologies, such as Node.js and Express.js, for developing and maintaining APIs and with database systems like MongoDB or MySQL for data storage and retrieval.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Experience with GraphQL for efficient data fetching and manipulation.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Develop and maintain mobile applications using React Native framework.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Develop and maintain both front-end and back-end infrastructure and databases required for the mobile applications.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Perform thorough testing and debugging to ensure the application's stability, performance, and security.</li>
                  <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;Participate in code reviews and provide constructive feedback to maintain code quality and ensure adherence to coding standards.</li>

                  </ul>
                  
                </p>
              </div>

            </article>


            <article className="entry">
              <h2 className="entry-title">
               Jobs for Phd Content Writer
              </h2>

              <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bx bx-rupee"></i> 15000 - 56000 /month</li>
                  <li className="d-flex align-items-center"><i className="bx bx-location-plus"></i> Trichy,India</li>
                </ul>
              </div>

              <div className="entry-content">
                <p>
                  <strong>Role &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Need to write paper for given concept or based on the base paper.<br/>
                  <strong>Job types&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Full-time, Part-time, Task based, Work from home  Permanent / Contract / Temporary <br/>
                  <strong>Schedule&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Day shift<br/>
                  <strong>Education&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong> Master,Phd (Preferred)<br/>
                  <strong>Skills require&nbsp;:</strong><ul>
                 <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;English Knowledge Must (  Paper write up with Grammar )</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;PhD Completed candidates those who are willing to work in full time / part time / task based / work from home also allowed</li>
                    <li className="d-flex align-items-left"><i className="bx bxs-extension"></i>&nbsp;&nbsp;CSE /ECE / Civil / Mechanical / EEE / Management / English candidates Phd Holders welcome.</li>
                  </ul>
                  
                </p>
              </div>
            </article>

           
            
            
          </div>
        </div>
      </div>
    </section>
    </main>
    </ScrollIntoView>
    </div>
  )
}

export default Career;