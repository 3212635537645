import React,{Component} from 'react';
import Navbar from './components/Navbar';
import Homecont from './pages/Homecont';
import Contact from './pages/Contact';
import Swaredev from './pages/Swaredev';
import Itinfra from './pages/Itinfra';
import Industrysol from './pages/Industrysol';
import Iot from './pages/Iot';
import Consulting from './pages/Consulting';
// import Brandpro from './pages/Brandpro';
import Dgmarketing from './pages/Dgmarketing';
import Webdev from './pages/Webdev';
import Appdev from './pages/Appdev';
import Uiuxdesign from './pages/uiuxdesign';
import Graphic from './pages/Graphic';
import Seosem from './pages/Seosem';
import Smm from './pages/Smm';
import Phdpdfassi from './pages/Phdpdfassi';
import Journals from './pages/Journals';
import Conferences from './pages/Conferences';
import Patentrights from './pages/Patentrights';
import Bookpublication from './pages/Bookpublication';
import Itservices from './pages/Itservices';
import Eventmgt from './pages/Eventmgt';
import KittleRandD from './pages/KittleRandD';
import Scopussci from './pages/Scopussci';
import Careers from './pages/Career';
import Payment from './pages/Payment';
import Teammember from './pages/Teammember';
import Footer from './components/Footer';
import Helmet from 'react-helmet';
import { BrowserRouter as Router,Routes,Route, BrowserRouter} from 'react-router-dom';
import './App.css';







// function App() {
//   return (
//     <div className="App">
//       <h1>Welcome to React</h1>
//     </div>
//   );
// }

class App extends Component{
  render(){
    return(
      <div>
        <Helmet>
                <script src="assets/vendor/purecounter/purecounter.js"></script>
                <script src="assets/vendor/aos/aos.js"></script>
                <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
                <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
                <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
                <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
                <script src="assets/vendor/waypoints/noframework.waypoints.js"></script>
                <script src="assets/vendor/php-email-form/validate.js"></script>
                <script src="assets/js/main.js"></script>
        </Helmet>
        <Router onUpdate={()=> window.scrollTo(0,0)}>
          <Navbar/>
            <Routes>
              <Route exact path='/'                          caseSensitive={false} element={<Homecont/>}/>
              <Route exact path='/softwaredevelopment'       caseSensitive={false} element={<Swaredev/>}/>
              <Route exact path='/itinfraservices'           caseSensitive={false} element={<Itinfra/>}/>
              <Route exact path='/industrialsolutions'       caseSensitive={false} element={<Industrysol/>}/>
              <Route exact path='/internetofthings'          caseSensitive={false} element={<Iot/>}/>
              <Route exact path='/consulting'                caseSensitive={false} element={<Consulting/>}/>
              {/* <Route exact path='/brandpromotion'        caseSensitive={false} element={<Brandpro/>}/> */}
              <Route exact path='/digitalmarketing'          caseSensitive={false} element={<Dgmarketing/>}/>
              <Route exact path='/webdevelopment'            caseSensitive={false} element={<Webdev/>}/>
              <Route exact path='/appdevelopment'            caseSensitive={false} element={<Appdev/>}/>
              <Route exact path='/uiuxdesign'                caseSensitive={false} element={<Uiuxdesign/>}/>
              <Route exact path='/graphicdesign'             caseSensitive={false} element={<Graphic/>}/>
              <Route exact path='/seovssem'                  caseSensitive={false} element={<Seosem/>}/>
              <Route exact path='/smm'                       caseSensitive={false} element={<Smm/>}/>
              <Route exact path='/phdpdfassistant'           caseSensitive={false} element={<Phdpdfassi/>}/>
              <Route exact path='/journalpublication'        caseSensitive={false} element={<Journals/>}/>
              <Route exact path='/conferences'               caseSensitive={false} element={<Conferences/>}/>
              <Route exact path='/patentrights'              caseSensitive={false} element={<Patentrights/>}/>
              <Route exact path='/bookpublications'          caseSensitive={false} element={<Bookpublication/>}/>
              <Route exact path='/itservices'                caseSensitive={false} element={<Itservices/>}/>
              <Route exact path='/kittlerd'                  caseSensitive={false} element={<KittleRandD/>}/>
              <Route exact path='/eventmanagement'           caseSensitive={false} element={<Eventmgt/>}/>
              <Route exact path='/scopusscijournalpublication'   caseSensitive={false} element={<Scopussci/>}/>
              <Route exact path='/careers'                  caseSensitive={false} element={<Careers/>}/>
              <Route exact path='/kittle'                   caseSensitive={false} element={<Contact/>}/>
              <Route exact path='/payment'                   caseSensitive={false} element={<Payment/>}/>
              <Route exact path='/team-member'                   caseSensitive={false} element={<Teammember/>}/>
            </Routes>
          <Footer/>
        </Router>
       
      </div>
    )
  }
}

export default App;


// import React, { Component } from 'react';
// import { BrowserRouter ,Routes,Route,Navigate} from 'react-router-dom';
// import Kittle from './kittleindex';
// import Kaster from './kaster/pages/kasterindex';
// import KittleLayout from './layout/Kittlelayout';
// import KasterLayout from './layout/Kasterlayout' ;
// import './App.css';

// const KittleLayoutRoute = ({component: Component,layout:KittleLayout, ...rest}) => {
//   return(
//     <Route
//     {...rest}
//     render={props => (
//     <KittleLayout>
//       <Component {...props} />
//     </KittleLayout>
//     )}
//     />
//   );
//   };
  
//   const KasterLayoutRoute = ({component:Component,layout:KasterLayout, ...rest}) => {
//     return(
//       <Route
//       {...rest}
//       render = {props => (
//            <KasterLayout>
//             <Component {...props}/>
//            </KasterLayout>
//         )}
//       />
//     );
//   };

// class App extends Component {
//   render() {
//     return (
//       <div>
//         <BrowserRouter>
//           <Routes>
//             <Route>
//                 <Route exact path="/" component={<Navigate replace to="/home"/>} />
//             </Route>
//             {/* --------Kittle Layout------------- */}
//             <KittleLayoutRoute path="/home" layout={KittleLayout} component={Kittle} />
//             {/* --------Kaster Layout------------- */}
//             <KasterLayoutRoute path="/kaster" layout={KasterLayout} component={Kaster} />
//           </Routes>
//         </BrowserRouter>
//       </div>
//     )
//   }
// }

// export default App;