import React from 'react';
import './Contact.css';
import { Link } from 'react-router-dom';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Contact = () => {
  return (
    <div>
      <Helmet>
      <title>Kittle Pvt Ltd - Kittle</title>
        <meta name='description' content='Kittle Private Ltd., is a growth partner. We are a nimble, hungry, results-driven firm. We execute with purpose and focus on measurable, actionble results.'/>
        <meta name='keywords' content='Kittle Private Limited, Software Development, Web Development, Digital Marketing, SEO, SEM, R&D, UI/UX design, Graphic Design, Consultancy, Scopus and SCI publication, Web of Science Journal publication Assistant, International Journal Publication'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">

    {/* <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>About Kittle</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>About Kittle</li>
          </ol>
        </div>

      </div>
    </section> */}

<div className="section-title">
         <h2>About Kittle</h2>
         <p>
         Kittle Pvt Ltd, We provide a full set of IT services, research and digital marketing services to drive your company’s growth. 
         We tailored the services to individual needs like Software Development, Web Development, Digital Marketing, SEO, SEM, R&amp;D, 
         UI/UX design, Graphic Design, and Consultancy.</p>
       </div>

    <section className="about" data-aos="fade-up">
      <div className="container">

        <div className="row">
          <div className="col-lg-6">
            <img src="assets/img/about.jpg" className="img-fluid" alt="imagecontact"/>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            {/* <h3>About Kittle</h3> */}
            <p className="text-justify">
            Kittle Private Ltd., is a growth partner. We're a nimble, hungry, results-driven firm. 
            We execute with purpose and focus on measurable, actionble results.
            We are a team of visionary designers, curious problem solvers and passionate collaborators, 
            who believe that great designs are made
            when stratergy and creativity work together.
            </p>
            <p className='text-justify'>
            We examine your brand from every angle. We look at the smallest details, bringing together 
            market insights and business goals to create a focused solution. Our goal is not only to 
            create designs that look good, but designs that serve a purpose.
            </p>
            {/* <p>
            We are much entrepreneurial as we are creative. So we'll care for your business as we do our
            own.
            </p> */}
            <p>
            We are much entrepreneurial as we are creative. Our devoted team of professionals is ethusiastic about their duties and committed to assisting your company’s growth. So we'll care for your business as we do our
            own.
            </p>
            <h2>Our Team</h2>
            <p>
            <Link to="/team-member">Here our team of professionals...</Link>
            </p>
          </div>
        </div>

      </div>
    </section>
    </main>
    </ScrollIntoView>
    </div>
  )
}

export default Contact;