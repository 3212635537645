import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Phdpdfassi = () => {
  return (
    <div>
      <Helmet>
      <title>PhD / PDF Assistant & Guidance – Kittle Pvt Ltd</title>
        <meta name='description' content='PhD / PDF assistance from Kittle is the topmost destination for PhD scholars to progress their research in an efficient way. Our team can help you by providing the best PhD / PDF assistance services.'/>
        <meta name='keywords' content='Domain Selection, Guide Arrangement, Topic Selection, Publication Assistant, Publication Support, Implementation & Results, Research Methodology'/>
      </Helmet>
      <ScrollIntoView>
          <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Phd and Pdf Assistant</h2>
         <p>We are a multidisciplinary team of published researchers and engineers, working for enabling 
            science goes beyond helping to solve today's problems; and prepared to solve tomorrow's known 
            and anticipated challenges through collaborative scientific investigations and modern 
            computational methods.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-14.jpeg" className="img-fluid" alt="phdpdf"/>
         </div>
         <div className="col-md-7 pt-4">
         <p>
         The toughest part of a doctoral course is writing a thesis. Since timely submission of thesis 
         and its quality is so critical to getting the degree, it is highly recommended that scholars 
         avail expert guidance. We at Thesis India offers the best in class thesis writing assistance 
         in the most ethical manner. Our writers offering PhD thesis writing services in India, guide 
         the scholars to prepare the framework flawlessly for thesis chapters. Then the scholars develop 
         the chapters, taking regular feedbacks from the writers.
           </p>
           <p>
           Hence, it is the researchers’ ideas and thoughts that go in to preparing the thesis. Some of 
           the guidelines that we follow for PhD and Pdf thesis writing are as follows:
           </p>
           <ul>
             <li><i className="bi bi-check"></i> Formatting rules</li>
             <li><i className="bi bi-check"></i> Language and Grammar</li>
             <li><i className="bi bi-check"></i> Authenticity </li>
             <li><i className="bi bi-check"></i> Citation</li>
             <li><i className="bi bi-check"></i> Punctuality</li>
           </ul>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Phdpdfassi