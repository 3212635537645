import React from 'react';
import './Swaredev.css';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';


const swaredev = () => {
  return (
    <div>
      <Helmet>
      <title>Software development Company - Kittle</title>
        <meta name='description' content='India #1 software Development Company that designs and builds scalable software solutions for enterprises and startups.'/>
        <meta name='keywords' content='best software development company, best companies in trichy, Top companies in trichy, Customer based company'/> 
      </Helmet>
      <ScrollIntoView>
        <main id="main">
            <section className="features">
      <div className="container">
        <div className="section-title">
          <h2>Software Development</h2>
          <p>we’re the experts in 
              designing and developing technology products that help your business streamline processes 
              and grow its revenue. We build custom software applications that are tailored to meet 
              your specific use case, budget, and timeline.</p>
        </div>

        <div className="row" data-aos="fade-up">
          <div className="col-md-5">
            <img src="assets/img/features-1.png" className="img-fluid" alt="swaredevimage"/>
          </div>
          <div className="col-md-7 pt-4">
            <ul>
              <li><i className="bi bi-check"></i> Software Consulting.</li>
              <li><i className="bi bi-check"></i> Custom Software Development</li>
              <li><i className="bi bi-check"></i> Enterprise Software Solutions</li>
              <li><i className="bi bi-check"></i> Maintenance & Support</li>
              <li><i className="bi bi-check"></i> Quality Assurance</li>
              <li><i className="bi bi-check"></i> Digital Transformation</li>
            </ul>
            <p>
            We transform your business by building innovative digital applications. Get started today, 
              and let us help you harness the power of emerging technologies.
            </p>
          </div>
        </div>
      </div>
    </section>
        </main>
        </ScrollIntoView>
        </div>
  )
}

export default swaredev;