import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Patientrights = () => {
  return (
    <div>
      <Helmet>
      <title>Patent Rights ( Intellectual Property ) – Kittle Pvt Ltd</title>
        <meta name='description' content='Patent is a monopoly right granted by the Government to exclude others from exploiting or using a particular invention.'/>
        <meta name='keywords' content='Trade Mark, Registered, Patent Rights, Intellectual Property, Content rights'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Patient Rights</h2>
         <p>
           A Patent is a right to stop others from copying the invention. The right is provided by the 
           Government of a country where the Patent is to be filed. Now, to get the Patent, one must 
           follow the set Patent process of the country where one wishes to file the Patent.
           </p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-16.jpg" className="img-fluid" alt="patentrights"/>
         </div>
         <div className="col-md-7 pt-4">
           <p><strong>why a patent agent is important?</strong></p>
           <p>
           Let us assume that you have a new idea and you do not want others to copy your idea. So, you 
           will have to file and get a patent for your new idea. The process to file and get a patent is 
           complex and many documents need to be filed with the patent office. Also, the process has a 
           lot of steps and timelines. The patent agent helps the client in moving ahead with the complex 
           process easily. They prepare the patent in a format acceptable to the patent office. 
           This reduces the risk of receiving unnecessary objections.
           </p>
           <p>
           In conclusion, a Patent agent is an expert who can get a Patent for your new idea in a smooth 
           way. The technical background and knowledge of the Patent Laws helps to provide you the best 
           possible way to get the Patent. Therefore, it is advised to talk to a patent agent who can 
           help you navigate through the complex Patent process before filing a patent. IPexcel has the 
           best team of Patent Agents to provide you the best Patent service.
           </p>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Patientrights;