import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Seosem = () => {
  return (
    <div>
       <Helmet>
      <title>Search Engine Optimization & Search Engine Marketing - Kittle</title>
        <meta name='description' content='Kittle Marketing provides data-driven SEO and SEM services for B2B companies. We work with local and international webpages and e-commerces.'/>
        <meta name='keywords' content='Search Engine Optimization, Search Engine Marketing, SEO, SEM, Digital promotion, Digital ads, Page ranking, organic search, PPC'/>
      </Helmet>
      <ScrollIntoView>
          <main id="main">
           <section className="features">
                <div className="container">
                    <div className="section-title">
                        <h2>SEO Vs SEM</h2>
                        <p>we provide a full set of integrated services to drive your 
                            company’s digital growth. We have services tailored to individual needs, 
                            from complete <strong>SEO and SEM </strong> management and consulting. New in 2022, we are using AI (Machine Learning).</p>
                    </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-11.png" className="img-fluid" alt="seosemimage"/>
         </div>
         <div className="col-md-7 pt-4">
         <p>
         It’s next to impossible to keep up with the ever-changing landscape of SEO. Since Kittle 
         has been dedicated to the science and art of <strong>Search Engine Optimization</strong>, staying at the 
         forefront of SEO trends and changes in algorithms. When you let SEO Inc develop your search 
         engine optimization strategy with our advanced SEO services, it allows you to focus on your 
         business/industry while leaving the search engine optimization strategies and tactics to us. 
         Our longevity and commitment to excellence put us as the leading Professional SEO company in 
         the industry.
           </p>
           <p>
           We are renowned <strong>Search Engine Marketing </strong> agency that brings end-to-end services to 
           give your business the winning edge. With these services, you can avail the benefits of 
           optimum business leverage at low costs and low risk. Rather than following a standard approach 
           for each client, we design a custom SEM strategy to get the best results considering the 
           constraints, requirements, and objectives of your business. At the same time, we follow a 
           flexible approach to keep pace with the changing algorithms of the search engines to maximize 
           the ROI within an optimal time span. Our objective is to get you the desired traffic with the 
           help of search engines so that you can boost your sales and brand value as well.
           </p>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Seosem;