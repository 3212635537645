import React from 'react';
import './Eventmgt.css';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Eventmgt = () => {
  return (
    <div>
      <Helmet>
      <title>Kittle Pvt Ltd - Event Management</title>
        <meta name='description' content='Event management services in india from Kittle Pvt Ltd are domain experts and provide complete Event management services in India.'/>
        <meta name='keywords' content='Event management,Event Management Services,'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Event Management</h2>
         <p>Managing is something that everyone does not have. It is a special ability. And it changes 
             from each and every program. There will be an entertainment program like a concert or a 
             small but intimate birthday party. And if they are managed well you will get a memorable 
             moment in your life. We offer nothing less than that.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-20.jpg" className="img-fluid" alt="eventimage"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>Event management is one of the most thrilling careers available. The average event 
                 planner is a busybody in charge of creating a great experience for attendees.</p>

            <p>Have you ever thought about going into event management yourself?</p>

            <p>It is an exciting career choice, but it can also be daunting. You need to make sure 
                you know what you’re doing if you wish to succeed at event management.</p>

            <p>For this, you’ll have to know the 5 C’s of Event Management.</p>
              
                     <ul>
                        <li><i className="bi bi-check"></i> Concept.</li>
                        <li><i className="bi bi-check"></i> Coordination</li>
                        <li><i className="bi bi-check"></i> Control</li>
                        <li><i className="bi bi-check"></i> Culmination</li>
                        <li><i className="bi bi-check"></i> Closeout</li>
                     </ul>
                 
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Eventmgt;