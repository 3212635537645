import React from 'react';
import './Navbar.css';
import { Link,NavLink } from 'react-router-dom';

const Navbar = ()=>{
    return(
        <>
        <header id="header" className="fixed-top d-flex align-items-center header-transparent">
    <div className="container d-flex justify-content-between align-items-center">

      <div className="logo">
      
       
        <Link className="nav-item" to="/"><img src="assets/img/logo.png" alt="" className="img-fluid"/></Link>
        
      </div>

      <nav id="navbar" className="navbar">
        <ul>
        <li><NavLink className="nav-item active " to="/">Home</NavLink></li>
          <li className="dropdown">
            <Link to="/"><span>Services</span> <i className="bi bi-chevron-down"></i></Link>
            <ul id="dropdown1">
              <li><NavLink className="nav-item" to="/softwaredevelopment">Software Development</NavLink></li>
              <li><NavLink className="nav-item" to="/itinfraservices">IT Infra Services</NavLink></li>
              <li><NavLink className="nav-item" to="/industrialsolutions">Industrial Solutions</NavLink></li>
              <li><NavLink className="nav-item" to="/internetofthings">IoT</NavLink></li>
              <li><NavLink className="nav-item" to="/consulting">Consulting</NavLink></li>
              {/* <li><NavLink className="nav-item" to="/brandpromotion">Brand Promotion</NavLink></li> */}
              <li><NavLink className="nav-item" to="/digitalmarketing">Digital Marketing</NavLink></li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to="/"><span>Web Services</span> <i className="bi bi-chevron-down"></i></Link>
            <ul id="dropdown2">
              <li><NavLink className="nav-item" to="/webdevelopment">Web Development</NavLink></li>
              <li><NavLink className="nav-item" to="/appdevelopment">App Development</NavLink></li>
              <li><NavLink className="nav-item" to="/uiuxdesign">UI/UX Design</NavLink></li>
              <li><NavLink className="nav-item" to="/graphicdesign">Graphic Design</NavLink></li>
              <li><NavLink className="nav-item" to="/seovssem">SEO/SEM</NavLink></li>
              <li><NavLink className="nav-item" to="/smm">SMM</NavLink></li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to="/#"><span>R & D</span> <i className="bi bi-chevron-down"></i></Link>
            <ul id='dropdown3'>
              <li><NavLink className="nav-item" to="/journalpublication">Journal Publication</NavLink></li>
              <li><NavLink className="nav-item" to="/phdpdfassistant">Phd / Pdf Assistant</NavLink></li>
              <li><NavLink className="nav-item" to="/conferences">Conferences</NavLink></li>
              <li><NavLink className="nav-item" to="/patentrights">Patent Rights</NavLink></li>
              <li><NavLink className="nav-item" to="/bookpublications">Book Publications</NavLink></li>
            </ul>
          </li>
          <li><NavLink className="nav-item" to="/careers">Careers</NavLink></li>
          <li><NavLink className="nav-item" to="/kittle">Kittle</NavLink></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>
  </header>
        </>
    )
}

export default Navbar;