import React from 'react';
import './Teammember.css';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

function Teammember() {
    return (
        <div>
      <Helmet>
      <title>Kittle Pvt Ltd - Kittle</title>
        <meta name='description' content='Kittle Private Ltd., is a growth partner. We are a nimble, hungry, results-driven firm. We execute with purpose and focus on measurable, actionble results.'/>
        <meta name='keywords' content='Kittle Private Limited, Software Development, Web Development, Digital Marketing, SEO, SEM, R&D, UI/UX design, Graphic Design, Consultancy, Scopus and SCI publication, Web of Science Journal publication Assistant, International Journal Publication'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">

    {/* <section className="breadcrumbs">
      <div className="container">

        <div className="d-flex justify-content-between align-items-center">
          <h2>About Kittle</h2>
          <ol>
            <li><Link to="/">Home</Link></li>
            <li>About Kittle</li>
          </ol>
        </div>

      </div>
    </section> */}

<div className="section-title">
         <h2>Our Team</h2>
         <p>
         We are much entrepreneurial as we are creative. Our devoted team of professionals is ethusiastic about their duties and committed to assisting your company’s growth. So we'll care for your business as we do our
            own.</p>
       </div>

    <section className="about" data-aos="fade-up">
      <div className="container">

        <div className="row">

          <div className="col-lg-12 pt-4 pt-lg-0">
          <table class="table table-bordered">
    <thead>
      <tr>
        {/* <th>Employee Id</th> */}
        <th>Employee Name</th>
        <th>Designation</th>
      </tr>
    </thead>
    <tbody>
    <tr>
<td>Raja M	</td>
<td>Director</td>
</tr>
    <tr>
<td>Abirami R	</td>
<td>Digital Marketing Analyst</td>
</tr>
<tr>
<td>Anas S	</td>
<td>Video Editor</td>
</tr>
<tr>
<td>Antony Ravi R	</td>
<td>Marketing Executive</td>
</tr>
<tr>
<td>Arumugam M	</td>
<td>Digital Marketing Analyst</td>
</tr>
<tr>
<td>Arun Kumar S	</td>
<td>Graphic Designer</td>
</tr>
<tr>
<td>Ashok Kumar B	</td>
<td>Digital Marketing Analyst</td>
</tr>
<tr>
<td>Flora Jannet F	</td>
<td>Content Writer</td>
</tr>
<tr>
<td>Ganesan M </td>
<td>IT Technical Assistant</td>
</tr>
<tr>
<td>Ganeshkumar M	</td>
<td>Digital Marketing Manager</td>
</tr>
<tr>
<td>Gireesh R	</td>
<td>Digital Marketing Analyst</td>
</tr>
<tr>
<td>Hari T	</td>
<td>Web Developer</td>
</tr>
<tr>
<td>Kameshwaran M	</td>
<td>HR & PA to CEO</td>
</tr>
<tr>
<td>Mohamed Abdullah M	</td>
<td>Marketing Executive</td>
</tr>
<tr>
<td>Parameshwaran S</td>
<td>Graphic Designer </td>
</tr>
<tr>
<td>Saravana Kumar M	</td>
<td>Administrator</td>
</tr>
<tr>
<td>Santhosh Sivan V</td>
<td>Marketing Executive</td>
</tr>
<tr>
<td>Sivakumar P	</td>
<td>Marketing Executive</td>
</tr>
<tr>
<td>Sheik Abdullah S	</td>
<td>Digital Marketing Analyst</td>
</tr>
<tr>
<td>Sheik Abdullah J	</td>
<td>Web Developer</td>
</tr>
<tr>
<td>Soundararajan R	</td>
<td>Web Developer</td>
</tr>
<tr>
<td>Sunilkumar S	</td>
<td>Software Engineer</td>
</tr>
<tr>
<td>Surya Prakash S	</td>
<td>Web Developer</td>
</tr>
<tr>
<td>Vignesh K</td>
<td>Marketing Executive</td>
</tr>
<tr>
<td>Vimal Raj P</td>
<td>Photographer cum Video Editor</td>
</tr>
<tr>
<td>Nagarajan M.T	</td>
<td>Marketing Executive</td>
</tr>
    </tbody>
  </table>
          </div>
        </div>

      </div>
    </section>
    </main>
    </ScrollIntoView>
    </div>
    )
}

export default Teammember;
