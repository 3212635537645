import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Bookpublication = () => {
  return (
    <div>
      <Helmet>
      <title>Book Publications with ISBN – Kittle Pvt Ltd</title>
        <meta name='description' content='Publishing platforms for books, journals, conference proceedings. Kittle provides a holistic range of solutions for printing era.'/>
        <meta name='keywords' content='ISBN, ISSN, Print Version, Book Publication, Book Formatting, Book printing, Book editing, Book Promotion'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Book Publications</h2>
         <p>we are changing the nature of the traditional academic book. Our books are published in 
             hardback, paperback, pdf and ebook editions, but they also include a free online edition 
             that can be read via our website, downloaded, reused or embedded anywhere.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-17.jpg" className="img-fluid" alt="bpcation"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>
             Our vision is to help every aspiring writer give their idea an identity, a tangible form and
              a medium to spread it. In our quest to redefine the publishing space, we constantly 
              strive to innovate and evolve the publishing process. Our publishing platform enables 
              writers to convert their group of research ideas into books that people can hold, read and 
              connect with.
             </p>
          
           <p>
           Today, <strong>Kittle private ltd</strong>., offers various publishing, book printing and distribution options 
           to both authors and publishers from around the world. We are a technology start-up & one of 
           the fastest growing book publishing companies in India that aims to solve problems in book 
           publishing and distribution by creating highly scalable solutions that work across the globe.
           </p>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Bookpublication; 