import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Appdev = () => {
  return (
    <div>
       <Helmet>
      <title>Mobile Application Development & Designing – Kittle</title>
        <meta name='description' content='Kittle App Developers is one of the veteran mobile app development companies in India. we have featured an incredible track record of developing staggeringly unique, complex, and futuristic mobile apps.'/>
        <meta name='keywords' content='Mobile Application Development company, IoS Application software’s, Android Application Designing, Mobile App bug fixing, Mobile App creation, Mobile Application Development & Designing'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>App Development</h2>
         <p>Kittle Private Ltd., is a leading App development company. Our expert team of App 
             Developers & Digital Strategists combine intelligent architecture and user-friendly 
             practices to create interactive business and e-commerce apps.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-8.png" className="img-fluid" alt="features8image"/>
         </div>
         <div className="col-md-7 pt-4">
         <p>
         We can build robust, fully scalable and secure enterprise grade native mobile apps on the iOS 
         and Android platforms. Additionally, as experts at HTML5 mobile development, Appnovation can 
         also build cross-platform mobile applications that will work seamlessly on any device or platform.
           </p>
           <p>
           Companies and organizations choosing Appnovation as their mobile application development 
           services company for their custom app development needs can be assured that their final 
           deliverable, no matter the technology it’s built on, will be of the highest of quality, 
           thoroughly tested and completely sustainable in whatever environment it’s hosted.
           </p>
           <p>
           Furthermore, as a full service mobile app development firm we offer a complete suite of 
           offerings in addition to development that include mobile strategy, design, integration, 
           migration and upgrade, as well as through our Managed Services group, are able to provide 
           24x7 support and maintenance.  
           </p>
           <ul>
             <li><i className="bi bi-check"></i> iOS Development</li>
             <li><i className="bi bi-check"></i> Android Development</li>
             <li><i className="bi bi-check"></i> React Native Development</li>
             <li><i className="bi bi-check"></i> Flutter Development</li>
             <li><i className="bi bi-check"></i> AngularJs Development</li>
           </ul>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Appdev;