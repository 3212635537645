import React,{useState} from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";







const Payment = () => {
const [description,setDescription] = useState()
const [amount, setAmount] = useState();
const [orderID,setOrderID] =useState(false);
// const [show, setShow] = useState(false);
 const [success, setSuccess] = useState(false);
 const [ErrorMessage, setErrorMessage] = useState("");


// creates a paypal order
const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: {description},
            amount: {
              currency_code: "USD",
              value: {amount},
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
 const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
    });
  };

   //capture likely error
 const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };


  return (
    <div>
    <Helmet>
    <title>Kittle Pvt Ltd - Kittle</title>
      <meta name='description' content='Kittle Private Ltd., is a growth partner. We are a nimble, hungry, results-driven firm. We execute with purpose and focus on measurable, actionble results.'/>
      <meta name='keywords' content='Kittle Private Limited, Software Development, Web Development, Digital Marketing, SEO, SEM, R&D, UI/UX design, Graphic Design, Consultancy, Scopus and SCI publication, Web of Science Journal publication Assistant, International Journal Publication'/>
      
    </Helmet>
    <ScrollIntoView>
       <main id="main">

  {/* <section className="breadcrumbs">
    <div className="container">

      <div className="d-flex justify-content-between align-items-center">
        <h2>About Kittle</h2>
        <ol>
          <li><Link to="/">Home</Link></li>
          <li>About Kittle</li>
        </ol>
      </div>

    </div>
  </section> */}

<div className="section-title">
       <h2>Payment</h2>
       <p>
       Kittle Pvt Ltd, We provide a full set of IT services, research and digital marketing services to drive your company’s growth. 
       We tailored the services to individual needs like Software Development, Web Development, Digital Marketing, SEO, SEM, R&amp;D, 
       UI/UX design, Graphic Design, and Consultancy.</p>
     </div>

  <section className="about" data-aos="fade-up">
    <div className="container">

      <div className="row">
        <div className="col-lg-12">
        <PayPalScriptProvider
     options={{
       "client-id":"sb&enable-funding=venmo&currency=USD",
     }}
   >
        <div id="smart-button-container">
            <div style={{textAlign:"center"}}><label for="description">Article Id </label>
                <input type="text" name="descriptionInput" id="description" maxlength="127" value={description} onChange={e => e.target.value}/>
            </div>
            <p id="descriptionError" style={{visibility: "hidden",color:"red", textAlign:"center"}}>Please enter a description</p>
        <div style={{textAlign:"center"}}><label for="amount">APC </label>
            <input name="amountInput" type="number" id="amount" value={description} onChange={e => e.target.value} /><span> USD</span>
        </div>
            <p id="priceLabelError" style={{visibility: "hidden",color:"red", textAlign:"center"}}>Please enter a price</p>
    
        <div id="invoiceidDiv" style={{textAlign:"center",display:"none"}}><label for="invoiceid"> </label>
            <input name="invoiceid" maxlength="127" type="text" id="invoiceid" value={orderID} onChange={e => e.target.value} />
        </div>
        <p id="invoiceidError" style={{visibility:"hidden",color:"red",textAlign:"center"}}>Please enter an Invoice ID</p>
        <div style={{textAlign:"center",marginTop:"0.625rem"}} id="paypal-button-container"></div>
      
       
         <PayPalButtons
           style={{ layout: "vertical" }}
           createOrder={createOrder}
           onApprove={onApprove}
         />
      
  </div>
  </PayPalScriptProvider>

        {/* <PayPalScriptProvider options={{ "client-id": "test" }}>
           <PayPalButtons style={{ layout: "horizontal" }} />
       </PayPalScriptProvider> */}


        </div>
        
      </div>

    </div>
  </section>
  </main>
  </ScrollIntoView>
  </div>
  )
}

export default Payment;

// export class Payment extends Component {
//   render() {
//     return (
//         <div>
//         <Helmet>
//         <title>Kittle Pvt Ltd - Kittle</title>
//           <meta name='description' content='Kittle Private Ltd., is a growth partner. We are a nimble, hungry, results-driven firm. We execute with purpose and focus on measurable, actionble results.'/>
//           <meta name='keywords' content='Kittle Private Limited, Software Development, Web Development, Digital Marketing, SEO, SEM, R&D, UI/UX design, Graphic Design, Consultancy, Scopus and SCI publication, Web of Science Journal publication Assistant, International Journal Publication'/>
          
//         </Helmet>
//         <ScrollIntoView>
//            <main id="main">
  
//       {/* <section className="breadcrumbs">
//         <div className="container">
  
//           <div className="d-flex justify-content-between align-items-center">
//             <h2>About Kittle</h2>
//             <ol>
//               <li><Link to="/">Home</Link></li>
//               <li>About Kittle</li>
//             </ol>
//           </div>
  
//         </div>
//       </section> */}
  
//   <div className="section-title">
//            <h2>Payment</h2>
//            <p>
//            Kittle Pvt Ltd, We provide a full set of IT services, research and digital marketing services to drive your company’s growth. 
//            We tailored the services to individual needs like Software Development, Web Development, Digital Marketing, SEO, SEM, R&amp;D, 
//            UI/UX design, Graphic Design, and Consultancy.</p>
//          </div>
  
//       <section className="about" data-aos="fade-up">
//         <div className="container">
  
//           <div className="row">
//             <div className="col-lg-12">
              
//             </div>
            
//           </div>
  
//         </div>
//       </section>
//       </main>
//       </ScrollIntoView>
//       </div>
//     )
//   }
// }

// export default Payment