import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';
const Iot = () => {
  return (
    <div>
      <Helmet>
      <title>Internet of Things ( IoT ) – Solution and Services</title>
        <meta name='description' content='Our IoT Capabilities. We offer easily deployable solutions for enterprises that gather data from sensors to provide actionable business insights.'/>
        <meta name='keywords' content='Internet of Things,  IoT Solution, IoT Services, IoT Applications , IoT Company, IoT Research, IoT Programs, IoT devices'/>
      </Helmet>
      <ScrollIntoView>
        <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Internet of Things(IOT)</h2>
         <p>
         Don’t get stuck in proof of concept – we can help you simplify the complex landscape. Tap into 
         our in-house expertise for a complete & secure solution from sensor to cloud. Leverage our 
         technology building blocks to reduce risk and realize faster time to value.Our focus is to help 
         you capitalize on the value of technology to achieve your business outcomes without delay. 
         Explore our IoT solutions, services and products to bring digital transformation to your 
         business.
         </p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-4.png" className="img-fluid" alt="iotimage"/>
         </div>
         <div className="col-md-7 pt-4">
            <p>
            The rapid expansion of devices creates opportunities to gain faster insights by connecting the 
            unconnected. HPE’s Internet of Things (IoT) solutions offer new ways to drive efficiencies, 
            engage customers, and develop new business with greater insights at the Intelligent Edge.</p>
             <p>
             Accelerate your responsiveness to new insights and monetise data from connected devices with 
             solutions to help you transform to an IoT-enabled, future-ready organisation.
             </p>
           <ul>
             <li><i className="bi bi-check"></i> Connected new and existing devices</li>
             <li><i className="bi bi-check"></i> Protect devices and data</li>
             <li><i className="bi bi-check"></i> Explore partner solutions</li>
           </ul>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Iot