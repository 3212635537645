import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';


const KittleRandD = () => {
  
  return (
    <div>
    <Helmet>
    <title>Research and Development -  Kittle</title>
      <meta name='description' content="We appreciate that your Research and Development laboratory demands may not always follow compendial principles or previously established 
                                        methods at Kittle Laboratories. We understand that there are instances when you need to think 'beyond the box'.
                                        That's why the Research and Development Laboratories at Kittle Laboratories are built to be adaptable to your demands."/>
      <meta name='keywords' content="Kittle Pvt Ltd R &amp; D Lab,Individuals, College,Institution, projects"/>
    </Helmet>
    <ScrollIntoView>
      <main id="main">
         <section className="features">
   <div className="container">

     <div className="section-title">
       <h2>Kittle R &amp; D Lab</h2>
       <p>
       Kittle Pvt Ltd R &amp; D Lab undertaking all type of Govt and Non Govt research funded projects. Any individuals / College / Institution can collaborate with our 
       R &amp; D Lab and can finish their projects and goals. Writing a good proposal is the need of the hour.  If you need novel research proposal writing help, we 
       have a team of subject-matters to create the Best Research Proposal all the funding agencies of India. The proposal we will be developed by the team of subject 
       matter experts and academic writers which have been developing several research proposals and thus it can be assured that the quality is as expected by funding 
       agencies.</p>
     </div>

     <div className="row" data-aos="fade-up">
       <div className="col-md-5">
         <img src="assets/img/R-D.jpg" className="img-fluid" alt="r&d"/>
       </div>
       <div className="col-md-7 pt-4">
           <p>
           The research proposal should be written in such a way where it must make a positive and powerful first impression about your potential to become a good 
           researcher and should enable the funding agencies to examine whether you are a good research expertise. Our proposal writing service offers the problem 
           definition and enhanced solutions through advanced intelligent methods includes expected outcome and limitations section of the research, with no language 
           glitches. We can adhere the guidelines and work according to that. However, writing such task is not easy and straightforward as it needs critical thinking and 
           analysis and proper communication of your ideas. Apart from all these skills, the task also needs significant investment in terms of the amount of time and energy.
           </p>
           <p>Kittle R &amp; D Lab will assist following govt funding schemes:</p>
           <div className='row'>
                <div className='col-md-12'>
                <ul>
                <li><i className="bi bi-check"></i>University Grants Commission (UGC) </li>
                <li><i className="bi bi-check"></i>All India Council for Technical Education (AICTE)</li>
                <li><i className="bi bi-check"></i>Council of Scientific & Industrial Research (CSIR) [MHRD-India]</li>
                </ul>
                </div>
                
            </div>
            {/*<p>Kittle R &amp; D Lab will assist following govt funding schemes:</p>
              <div className='row'>
                  <div className='col-md-12'><ul>
                  <li><i className="bi bi-check"></i>University Grants Commission (UGC) </li>
                  <li><i className="bi bi-check"></i>All India Council for Technical Education (AICTE)</li>
                  <li><i className="bi bi-check"></i>Council of Scientific & Industrial Research (CSIR) [MHRD-India]</li>
                  <li><i className="bi bi-check"></i>Defense Research & Development Organization (DRDO)</li>
                  <li><i className="bi bi-check"></i>Department of Atomic Energy (DAE) </li>
                  <li><i className="bi bi-check"></i>Department of Coal (DOC)</li>
                  <li><i className="bi bi-check"></i>Department of Ocean Development (DOD)</li>
                  <li><i className="bi bi-check"></i>Department of Science and Technology (DST)</li> 
                  <li><i className="bi bi-check"></i>Department of Scientific and Industrial Research (DSIR)</li>
                  <li><i className="bi bi-check"></i>Indian Council of Medical Research (ICMR)</li>
                  <li><i className="bi bi-check"></i>Department of Biotechnology (DBT)</li>
                  <li><i className="bi bi-check"></i>Ministry of Environment, Forest, and Climate Change (MOEF-CC)</li>
                  <li><i className="bi bi-check"></i>Ministry of Communications & Information Technology (MOCIT) (Now: DoT, and MeitY)</li>
                  <li><i className="bi bi-check"></i>Indian Space Research Organization (ISRO) - Department of Space (DoS)</li>
                  <li><i className="bi bi-check"></i>India Meteorological Department (IMD)</li>
                  <li><i className="bi bi-check"></i>Science and Engineering Research Board (SERB)</li>
                  <li><i className="bi bi-check"></i>Science & Technology for Weaker Sections (STAWS)</li>
                  <li><i className="bi bi-check"></i>Science and Technology Application for Rural Development (STARD)</li>
                  <li><i className="bi bi-check"></i>Ministry of Water Resources (MOWR)</li>
                  <li><i className="bi bi-check"></i>Ministry of Power, Central Power Research Institute (CPRI)</li>
                  <li><i className="bi bi-check"></i>Ministry of Non-Conventional Energy Sources (MNES)</li>
                  <li><i className="bi bi-check"></i>Ministry of Food Processing Industries (MFPI)</li>
                  <li><i className="bi bi-check"></i>Indian Council for Social Science Research</li>
                  <li><i className="bi bi-check"></i>Department of Chemicals & Petrochemicals, Ministry of Chemicals & Fertilizers</li>
                  <li><i className="bi bi-check"></i>Department of Education (DOE)</li>
                  <li><i className="bi bi-check"></i>Department of Electronics and Information Technology</li>
                  <li><i className="bi bi-check"></i>Indian National Science Academy (INSA)</li>
                  <li><i className="bi bi-check"></i>Indian Council of Agricultural Research (ICAR)</li>
           </ul>
          </div>
          </div> */}
        
       </div>


     </div>

   </div>
 </section>
 {/* <section className='features'>
  <div className='container'>
    <div className='col-md-4'>
    Button trigger modal 
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
University Grants Commission (UGC)
</button>

  Modal  
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
    </div>
  </div>
 </section>
 <section className='features'>
 <div className='container mt-5'>
       <div className='row'>
          
           <p>Kittle R &amp; D Lab will assist following govt funding schemes:</p>
               <div className='col-md-4'>
               <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>University Grants Commission (UGC)</h6></Accordion.Header>
                            <Accordion.Body>
                            <ul>
                                <li>Major and Minor Research Projects</li>
                            </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>All India Council for Technical Education (AICTE)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Council of Scientific &amp; Industrial Research (CSIR) [MHRD-India]</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Defense Research &amp; Development Organization (DRDO)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Department of Atomic Energy (DAE)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Department of Coal (DOC)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Department of Ocean Development (DOD)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Department of Science and Technology (DST)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
               </div>
                              
               <div className='col-md-4'>
               <Accordion>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Department of Scientific and Industrial Research (DSIR)</h6></Accordion.Header>
                            <Accordion.Body>
                            <ul>
                                <li>Major and Minor Research Projects</li>
                            </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>All India Council for Technical Education (AICTE)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Indian Council of Medical Research (ICMR)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="11">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Department of Biotechnology (DBT)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="12">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Ministry of Environment, Forest, and Climate Change (MOEF-CC)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="13">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Ministry of Communications & Information Technology (MOCIT) (Now: DoT, and MeitY)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="14">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Indian Space Research Organization (ISRO) - Department of Space (DoS)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="15">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>India Meteorological Department (IMD)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
               </div>


               <div className='col-md-4'>
               <Accordion>
                    <Accordion.Item eventKey="16">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Science and Engineering Research Board (SERB)</h6></Accordion.Header>
                            <Accordion.Body>
                            <ul>
                                <li>Major and Minor Research Projects</li>
                            </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    <Accordion.Item eventKey="17">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>All India Council for Technical Education (AICTE)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="18">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Science & Technology for Weaker Sections (STAWS)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="19">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Science and Technology Application for Rural Development (STARD)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="20">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Ministry of Water Resources (MOWR)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="21">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Ministry of Power, Central Power Research Institute (CPRI)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="22">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Ministry of Non-Conventional Energy Sources (MNES)</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="23">
                        <Accordion.Header><h6 style={{fontSize:'13px'}}>Indian Council for Social Science Research</h6></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                        <li>Research &amp; Institutional Development Schemes
                            <ul>
                                <li>Modernization &amp; Removal of Obsolescence Scheme (MODROBS)</li>
                                <li>Research Promotion Schemes (RPS)</li>
                            </ul>
                        </li>
                        <li>Industry-Institute Interaction Schemes
                            <ul>
                                <li>Industry Institute Partnership Cell (IIPC)</li>
                                <li>Entrepreneurship Development Cells (EDC)</li>
                            </ul>
                        </li>
                    </ul>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
               </div>
           </div>
       
           </div>
 </section> */}
     </main>
     </ScrollIntoView>
  </div>
  )
}

export default KittleRandD