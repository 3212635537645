import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Journals = () => {
  return (
    <div>
      <Helmet>
      <title>International Journal Publication Support – Kittle </title>
        <meta name='description' content='Thinking About Submitting a Manuscript? Find Everything at Kittle Private Limited.'/>
        <meta name='keywords' content='International Journal Publication Support, Publication Assistant, Publication Write up, paper Write up, Thesis write up, Content Write up, Paper Publication, Paper Preparation, Paper Development, SCI & Scopus Publication, Web of Science publication'/>
      </Helmet>
      <ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">
       <div className="section-title">
         <h2>Journal Publication</h2>
         <p>  Kittle private ltd., is a service based nonprofit research organization founded in India, furnishing
              supports and services to education professionals and researchers around the globe without 
              any cost or financial expectation. Our mission has always focused on helping our 
              researchers succeed, wherever they are in their education and professional careers..</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-13.jpg" className="img-fluid" alt="journals"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>
             We are bridging the higher education gap by delivering content solutions in new and 
             innovative ways to enrich the learning experience. We are a proven leader in strategic 
             higher education consulting and partnering with educators and institutions globally to 
             achieve success, and our print and digital solutions enable students and instructors 
             worldwide. We encourage learning to be a continuous, lifelong experience – an integral, 
             essential part of every stage of building a career
             </p>
             <p>
             We are closing the talent deficit by supporting employers, helping them find and retain top 
             talent, assess their employees’ skill sets, and implement training in order to transform 
             company cultures. For decades we’ve helped people learn; today we’re showing them new ways 
             to achieve, get certified and advance their careers.
             </p>
           <p>
           We are strengthening the research community by partnering with learned societies and supporting
            researchers to communicate discoveries that make a difference. We collaborate with authors, 
            societies, libraries, and other members of the research community to generate, communicate, 
            and enable access to the scientific and scholarly insights that are helping to solve some of 
            the world’s biggest challenges.
           </p>
           <p>
           Our commitment to partnership helps us advance innovation and connect researchers, learners, 
           and professionals with the content, platforms, and tools they need to be successful.
           </p>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Journals;