import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Itinfra = () => {
  return (
    <div>
 <Helmet>
      <title>Infrastructure Services and Solutions - Kittle</title>
        <meta name='description' content='Kittle Pvt Ltd IT infrastructure services and solutions transform business operations.'/>
        <meta name='keywords' content='Infrastructure Services and Solutions,  IT Solutions, IT infrastructure, IT modules, IT Services, IT Providers, IT Industries'/>
      </Helmet>
<ScrollIntoView>
         <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>IT Infra Services</h2>
         <p>
Imagine a future where IT infrastructures can monitor themselves, predict and respond to future business 
needs and protect and heal themselves.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-2.png" className="img-fluid" alt="itinfraimage"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>
             In today’s digital and cloud-based world, your business performs only as well as your IT 
             infrastructure. It empowers the users and runs the applications that run the business. 
             A platform on which to build exceptional customer and employee experiences.
             </p>
             <p>
             We help companies evolve infrastructure from a capital-intensive, hardware-oriented 
             discipline to one that is modern, software-defined & intelligent.
             </p>
           <ul>
             <li><i className="bi bi-check"></i> Cloud and data center</li>
             <li><i className="bi bi-check"></i> Network</li>
             <li><i className="bi bi-check"></i> Digital workplace</li>
             <li><i className="bi bi-check"></i> Digital platforms</li>
             <li><i className="bi bi-check"></i> Service Management</li>
             <li><i className="bi bi-check"></i> Cloud and infrastructure security</li>
           </ul>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Itinfra