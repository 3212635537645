import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Graphic = () => {
  return (
    <div>
      <Helmet>
      <title>Graphic Designs, Animation, Content Development – Kittle</title>
        <meta name='description' content='We love designing logos, Poster, Broucher and take great pride in our work. Get in touch today and see how your business can benefit. Graphic Vision provides a Professional Logo design in India.'/>
         <meta name='keywords' content='Logo Design, Graphic Design, Poster Design, Motion design, Photo editing, Video editing, Video design, Video graphics, 2D & 3D animations'/>
      </Helmet>
      <ScrollIntoView>
          <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Graphic Design</h2>
         <p>
         We love designing logos and take great pride in our work. Get in touch today and see how your 
         business can benefit. Graphic Vision provides a Professional Logo design in India.
         </p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-10.jpg" className="img-fluid" alt="fea10image"/>
         </div>
         <div className="col-md-7 pt-4">
         <p>
         A Logo generally is a graphic mark or symbol representing a commercial company organizations 
         and even an individual for a public recognition. Some common types of Logo are:- 
         <ul>
         <li><i className="bi bi-check"></i>Symbol</li> 
         <li><i className="bi bi-check"></i>Word Mark</li> 
         <li><i className="bi bi-check"></i>Letter Mark</li> 
         <li><i className="bi bi-check"></i>Combination Mark </li>
         </ul>
         Emblem A key reason to create a 
         business logo is to start the process of building a brand image. Visual images go along with 
         your company name and slogan in helping to create a memorable impact with target audiences. 
         The key is to consistently apply your logo across all promotions. Many companies include logos 
         on business cards, letterhead and websites, in addition to all print ads. Ultimately, you want 
         customers to connect your logo to your business and its values.
         </p>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Graphic