import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Webdev = () => {
  return (
    <div>
      <Helmet>
      <title>Website Development & Designing – Kittle</title>
        <meta name='description' content='Kittle Pvt Ltd is a leading web development company in India offering E-commerce, professional website and web application development services worldwide.'/>
        <meta name='keywords' content='web development company in India, E-commerce websites, professional website designing, web application development services, Website Builder, Web application, Web development and maintenance'/>
      </Helmet>
      <ScrollIntoView>
        <main id="main">
           <section className="features">
     <div className="container">
       <div className="section-title">
         <h2>Web Development</h2>
         <p>Kittle Private Ltd., is a leading web development company. Our expert team of Website 
             Developers & Digital Strategists combine intelligent architecture and web-friendly 
             practices to create interactive business and e-commerce websites.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-7.png" className="img-fluid" alt="webdevimage"/>
         </div>
         <div className="col-md-7 pt-4">
         <p>
           We gives different scope of services in the field of web design and web development including 
           application development. We deliver unique websites to clients across a wide range of sectors. 
           We have a team of experts in :
           </p>
           <ul>
             <li><i className="bi bi-check"></i> Website Design</li>
             <li><i className="bi bi-check"></i> Website Development</li>
             <li><i className="bi bi-check"></i> News Portal Website</li>
             <li><i className="bi bi-check"></i> E-Commerce Solutions</li>
             <li><i className="bi bi-check"></i> CMS(Content Management Systems)</li>
             <li><i className="bi bi-check"></i> Word Press Website</li>
           </ul>
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Webdev;