import React from 'react';
import ScrollIntoView from '../components/ScrollIntoView';
import Helmet from 'react-helmet';

const Consulting = () => {
  return (
    <div>
      <Helmet>
      <title>Consulting and Strategic Planner -  Kittle</title>
        <meta name='description' content='Kittle Pvt Ltd is a leading management consulting firm in India advising top companies from all sectors on strategy, marketing, transformation and digital.'/>
        <meta name='keywords' content='Consulting Company, Strategic Plan & Movement, Adviser,  Brand promoter, Logic driven approach, Plan & Development, Planning and Growth'/>
      </Helmet>
      <ScrollIntoView>
        <main id="main">
           <section className="features">
     <div className="container">

       <div className="section-title">
         <h2>Consulting</h2>
         <p>
         Online Innovations is a long established company with our consulting team who are privileged 
             to have been exposed to a large number of companies and their diverse- systems, 
             brand/marketing requirements and demands.This exposure has broadened our knowledge of best
              practices and of the key business processes that drive modern business. Our track record 
              and portfolio alludes to the maturity at which we always aspire to conduct ourselves when 
              researching our customers' needs and tailoring innovative web strategies.</p>
       </div>

       <div className="row" data-aos="fade-up">
         <div className="col-md-5">
           <img src="assets/img/features-5.png" className="img-fluid" alt="consulting"/>
         </div>
         <div className="col-md-7 pt-4">
             <p>
             Our skilled team has a remarkably good balance of creative and logical comprehension and 
             understand the importance of brand and corporate identity as well as the boundaries and 
             opportunities that web technologies offer.
             </p>
             <p>Online Innovations is a full-service business consulting firm advising on web related 
                fields of:</p>
                <div className='row'>
                    <div className='col-md-6'><ul>
                    <li><i className="bi bi-check"></i> Information technology</li>
             <li><i className="bi bi-check"></i> Design</li>
             <li><i className="bi bi-check"></i> Development</li>
             <li><i className="bi bi-check"></i> E-Business</li>
             <li><i className="bi bi-check"></i> E-Commerce</li>
             <li><i className="bi bi-check"></i> Internet marketing</li>
             <li><i className="bi bi-check"></i> Social media</li>
             <li><i className="bi bi-check"></i> Hosting</li>
            
             </ul>
            </div>
            <div className='col-md-6'>
            <ul>       
             <li><i className="bi bi-check"></i>Engineering and Industrial Services</li>
             <li><i className="bi bi-check"></i>Banking, Financial Services</li>
             <li><i className="bi bi-check"></i>Billing software’s</li>
             <li><i className="bi bi-check"></i>Automation products</li>
             <li><i className="bi bi-check"></i>Life Sciences</li>
             <li><i className="bi bi-check"></i>Healthcare</li>
             <li><i className="bi bi-check"></i>Energy and Utilities</li>
             <li><i className="bi bi-check"></i>Manufacturing</li>
           </ul>
                    </div>
                </div>
          
         </div>
       </div>
     </div>
   </section>
       </main>
       </ScrollIntoView>
    </div>
  )
}

export default Consulting